<template>
  <slide-over-right :id="sorId" v-on:showModal="showModal" editType="scooter">
    <loading :active.sync="isLoading"></loading>
    <template v-slot:header>
      <div class="panel-title pb-3">{{ titleText }}</div>
    </template>
    <add-multiple-scooter-step-1 ref="step1" />

    <template v-slot:footer>
      <div class="flex">
        <div class="w-3/5">
          <i class="fas fa-download" style="color:rgb(141 141 141);"></i>
          <span class="underline cursor-pointer"><a href="">Download </a></span>
          <span class="normal">Excel sample file</span>
        </div>
        <div class="w-3/5 flex ">
          <slide-over-left-trigger :id="sorId" v-on:cancelEdit="showModal">
            <anchor-button variant="secondary" class="text-center custom-font">
              <span class="font-semibold custom-font">
                Cancel
              </span>
            </anchor-button>
          </slide-over-left-trigger>
          <!-- <t-button type="submit" class="mx-3" @click="submitStep(currentStep)">
            {{!isEditing ? "Save & Continue" : "Update"}}
          </t-button> -->
          <t-button type="submit" class="mx-3" @click="submitStep()">
            {{ !isEditing ? 'Upload' : 'Update' }}
          </t-button>
        </div>
      </div>
    </template>
  </slide-over-right>
</template>

<script>
// import { mapState } from "vuex";
import { mapGetters } from 'vuex'

import { EventBus } from '@/utils/EventBus'
import { MultipleScooterConfig } from '@/config/MultipleScooterConfig'
import { VehicleConfig } from '@/config/VehicleConfig'
import SlideOverRight from '@/components/modals/SlideOverRight'

import AddMultipleScooterStep1 from './AddMultipleScooterStep1.vue'

import SlideOverLeftTrigger from '@/components/modals/SlideOverLeftTrigger'
import AnchorButton from '@/components/form/AnchorButton.vue'

export default {
  name: 'AddMultipleScooter',
  components: {
    SlideOverRight,
    AddMultipleScooterStep1,
    SlideOverLeftTrigger,
    AnchorButton,
  },
  props: {
    is_edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sorId: MultipleScooterConfig.events.sorId,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('scooter', ['isEditing', 'currenteditingScooterId']),
    is_editing() {
      return this.isEditing
    },
    titleText() {
      return this.isEditing ? 'Edit Multiple Scooter' : 'Add Multiple Scooter'
    },
  },
  watch: {
    // organizationId: function(updatedId) {
    // }
  },
  async mounted() {
    EventBus.$on(MultipleScooterConfig.events.editingData, (item) => {
      //this.$store.dispatch("organization/saveStep", 1);
      this.$store.dispatch('scooter/forgetEditingStep')
      this.$store.dispatch('scooter/forgetAllStepsData')
      this.$store.dispatch('scooter/forgetEditingScooterId')

      console.log('item = ', item)
    })
    EventBus.$on(MultipleScooterConfig.events.successedMultiple, (item) => {
      console.log('success = ', item)
      dispatchEvent(new Event(VehicleConfig.events.refresh))
      dispatchEvent(new Event(MultipleScooterConfig.events.sorClose))
      // todo: Refetch the indexData
    })
  },
  methods: {
    showModal() {
      console.log('exit')
      dispatchEvent(new Event(MultipleScooterConfig.events.sorToggle))
    },
    changeTabs(index) {
      console.log('index = ', index)
    },
    submitStep(step = 0) {
      this.$refs[`step${step + 1}`].submit()
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
  margin-top: -30px;
  margin-bottom: 6px;
}
.underline {
  color: #000000;
  text-decoration: underline;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-left: 10px;
}
.normal {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6c6e71;
}
</style>
