import { render, staticRenderFns } from "./AddMultipleScooter.vue?vue&type=template&id=abf14550&scoped=true&"
import script from "./AddMultipleScooter.vue?vue&type=script&lang=js&"
export * from "./AddMultipleScooter.vue?vue&type=script&lang=js&"
import style0 from "./AddMultipleScooter.vue?vue&type=style&index=0&id=abf14550&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abf14550",
  null
  
)

export default component.exports