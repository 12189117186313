<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading"></loading>
    <form
      @submit.prevent="handleSubmit(submitUploadExcel)"
      enctype="multipart/form-data"
      class="px-6"
    >
      <!-- start: full-name & username -->
      <div class="w-3/5 pr-2">
        <ValidationProvider name="fleet" rules="required" v-slot="{ errors }">
          <input-label :error="errors[0]" text="Fleet *" />
          <t-rich-select
            name="options"
            :options="fleets"
            valueAttribute="id"
            textAttribute="name"
            :hideSearchBox="false"
            placeholder="Select"
            :variant="{ danger: !!errors[0] }"
            v-model="form.fleet"
          />
          <input-error-item :message="errors[0]" />
        </ValidationProvider>
      </div>
      <!-- end: full-name & username -->

      <!-- start: phone-number & email -->
      <div class="w-3/5 pr-2 mt-12">
        <ValidationProvider name="file" rules="required" v-slot="{ errors }">
          <input-label :error="errors[0]" text="File *" />
          <upload-excel
            v-model="form.file"
            :errors="errors[0] == undefined ? '' : errors[0]"
          ></upload-excel>

          <input-error-item :message="errors[0]" />
        </ValidationProvider>
      </div>
      <div v-if="form.file">
        {{ form.file.name }}
      </div>
      <!-- end: phone-number & email -->
      <button type="submit" ref="submitUploadButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import xMan from '@/utils/xMan'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { MultipleScooterConfig } from '@/config/MultipleScooterConfig'
//import { DropdownConfig } from "@/config/DropdownConfig";
import InputLabel from '@/components/form/InputLabel'
import UploadExcel from '@/components/form/UploadExcel.vue'

export default {
  name: 'addMultipleScooterStep1',
  components: {
    InputLabel,
    UploadExcel,
  },
  data: function() {
    return {
      isLoading: false,
      form: {},
      fleets: [],
      filelist: [], // Store our uploaded files
    }
  },
  async created() {
    this.fleets = await this.$http
      .get(MultipleScooterConfig.api.fleets)
      .then((res) => res.data.data)
      .catch((err) => console.log('err = ', err))
  },
  mounted() {
    //console.log("c4", this.step4.owner_user.id);
    if (this.isEditing) {
      this.form = { ...this.step4.form }
    }
  },
  computed: {
    ...mapGetters('scooter', ['isEditing', 'currenteditingScooterId']),
    ...mapState({
      // retrieve organization detail from step 1
      step1: (state) => state.scooter.step1,
      // retrieve subscription detail from step 2
      step2: (state) => state.scooter.step2,
      // retrieve payment & web-links detail from step 3
      step3: (state) => state.scooter.step3,
      // retrieve organization owner detail from step 4
    }),
    getPasswordValidationRules() {
      return this.isEditing ? 'min:8' : 'required|min:8'
    },
  },
  methods: {
    submit: async function() {
      this.$refs.submitUploadButton.click()
    },
    submitUploadExcel: async function() {
      this.isLoading = true
      let method = 'POST'
      let url = MultipleScooterConfig.api.multiple
      let data = new xMan(this.form).toFormData()

      let message = 'Scooter added successfully'
      try {
        let response = await this.$http({ method, url, data })
        this.isLoading = false
        // todo: remove logs
        console.log({ res: response.data })
        // Reset the form
        this.form = {}
        // Notify
        this.$notify(
          {
            group: 'important',
            type: 'success',
            title: 'Success',
            text: message,
          },
          5000
        )
        // Resolved
        console.log('response = ', response.data)
        EventBus.$emit(
          MultipleScooterConfig.events.successedMultiple,
          response.data
        )
        return true
      } catch (e) {
        this.isLoading = false
        console.log({ e1: e.response.data })
        var errorData = Object.values(e.response.data)
        if (errorData.length > 0) {
          this.$emit('show_error', errorData[0][0])
        }
        this.$notify(
          {
            group: 'important',
            type: 'error',
            title: 'Error ocurred!',
            text: e.response.data,
          },
          5000
        )
      }
    },
  },
}
</script>
